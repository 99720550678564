import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ListProductBrandService } from '@core/services/FiltersHandlers/list-product-brand.service';
import { ListProductBrandDiscountService } from '@core/services/FilterHandler/list-product-brand.service';
import { ListProductCriterionService } from '@core/services/FiltersHandlers/list-product-criterion.service';
import { ListProductFilterService } from '@core/services/FiltersHandlers/list-product-filter.service';
import { ListProductPriceService } from '@core/services/FiltersHandlers/list-product-price.service';
import { ListProductSubcategoryService } from '@core/services/FiltersHandlers/list-product-subcategory.service';
import { TipoSearch } from '@core/models/tipoSearch';
import { ReachOutService } from '@core/services/reach-out.service';
import { NavigationService } from '@core/services/navigation.service';
import { SearchConfig } from '@core/services/search-engine/search.config';

@Injectable({
  providedIn: 'root',
})
export class ChipsFiltrosService {
  filtrosSeleccionados = signal<Array<TipoSearch>>([]);
  constructor(
    private listProductCriterionService: ListProductCriterionService,
    private listProductSubcategoryService: ListProductSubcategoryService,
    private listProductBrandService: ListProductBrandService,
    private listProductBrandDiscountService: ListProductBrandDiscountService,
    private listProductFilterService: ListProductFilterService,
    private listProductPriceService: ListProductPriceService,
    private route: Router,
    private reachOutService: ReachOutService,
    private _navigationService: NavigationService,
  ) {
    this.filtrosSeleccionados.set([]);
  }

  addFiltro(filtro: TipoSearch) {
    if (this.filtrosSeleccionados().some((chip) => chip.dataId == filtro.dataId)) {
      return;
    }
    if (
      filtro.tipo == 'marca' &&
      this.filtrosSeleccionados().find((chip) => chip.tipo == 'marca')
    ) {
      return;
    }
    if (filtro.tipo == 'precio') {
      let index = this.filtrosSeleccionados().findIndex(
        (chip) => chip.tipo == 'precio' && chip.nombre.split(':')[0] == filtro.nombre.split(':')[0],
      );
      if (index != -1) {
        this.filtrosSeleccionados().splice(index, 1);
      }
    }
    if (
      filtro.tipo == 'subcategoria' &&
      this.filtrosSeleccionados().find((chip) => chip.tipo == 'subcategoria')
    ) {
      this.filtrosSeleccionados.set([]);
    }

    this.filtrosSeleccionados.update((v) => [...v, filtro]);
    this.filtrosSeleccionados.set(this.eliminarObjetosDuplicados(this.filtrosSeleccionados()));
  }
  getChips() {
    //aca eliminar dupli
    return this.eliminarObjetosDuplicados(this.filtrosSeleccionados());
  }
  armarChipsFiltros(arrayFiltros) {
    let subvalorBusqueda;
    arrayFiltros.forEach((item) => {
      if (item?.$valor !== undefined) {
        switch (item.opciones.tipo) {
          case 'buleano':
            if (item.$valor == '0') {
              subvalorBusqueda = 'No';
            } else {
              subvalorBusqueda = 'Si';
            }
            break;
          case 'cadena':
            subvalorBusqueda = item.$valor;
            break;
          case 'numerico':
            subvalorBusqueda = item.$valor;
            break;
          default:
            break;
        }

        this.filtrosSeleccionados.update((v) => [
          ...v,
          {
            tipo: 'filtro',
            dataId: `${item.id_campo_tabla}:${item.$valor}`,
            nombre: `${item.opciones.etiqueta}: ${subvalorBusqueda}`,
          },
        ]);
        this.filtrosSeleccionados.set(this.eliminarObjetosDuplicados(this.filtrosSeleccionados()));
      }
    });
  }
  eliminarObjetosDuplicados(arr: Array<TipoSearch>) {
    return arr.filter(
      (item, index, arr) => arr.findIndex((item2) => item2.dataId === item.dataId) === index,
    );
  }

  public async removeSearchFilter(item: TipoSearch, searchApplied: boolean): Promise<void> {
    let i: number;
    switch (item.tipo) {
      case SearchConfig.grouperProp:
        await this.resetTodosFiltros(SearchConfig.grouperProp);
        this.listProductSubcategoryService.clearSelectedSubcate();
        break;
      case 'subcategoria':
        await this.resetTodosFiltros(searchApplied ? 'search_results' : 'chip');
        this.listProductSubcategoryService.clearSelectedSubcate();
        break;
      case 'marca':
        this.listProductBrandService.changeBrand(null);
        break;
      case 'marca_discount':
        this.listProductBrandDiscountService.change(null);
        this.resetTodosFiltros('chip');
        break;
      case 'busqueda':
        this.listProductCriterionService.ejectuteChange(null);
        this.listProductSubcategoryService.clearSelectedSubcate();
        this.resetTodosFiltros('chip');
        break;
      case 'filtro':
        this.listProductFilterService.deleteFilter(item.dataId);
        break;
      case 'precio':
        this.listProductPriceService.deleteFilter(item.dataId);
        break;
      case 'alcance':
        this.reachOutService.deleteProductListReach();
        break;
      default:
        break;
    }
    i = this.filtrosSeleccionados().findIndex((filtro) => filtro.dataId == item.dataId);
    this.filtrosSeleccionados().splice(i, 1);
    this.filtrosSeleccionados.set(this.eliminarObjetosDuplicados(this.filtrosSeleccionados()));
  }

  async resetTodosFiltros(origen: string) {
    this.filtrosSeleccionados.set([]);
    this.listProductFilterService.clearFiltrosSubject();
    this.listProductPriceService.clearPreciosSubject();
    this.listProductBrandService.clearBrandSubject();
    this.listProductBrandDiscountService.clearBrandSubjectDiscount();
    switch (origen) {
      case 'chip':
        await this.limpiarUrlTotal();
        break;
      case 'boton':
        await this.limpiarUrlParcial();
        break;
      case 'search_results':
        await this._navigationService.navigate([], {
          queryParams: {
            precio: null,
            marca: null,
            filtros: null,
            cate: null,
          },
          queryParamsHandling: 'merge',
        });
        break;
      case SearchConfig.grouperProp:
        await this._navigationService.navigate([], {
          queryParams: {
            precio: null,
            marca: null,
            filtros: null,
            cate: null,
            [SearchConfig.grouperProp]: null,
          },
          queryParamsHandling: 'merge',
        });
        break;
    }
  }

  public resetFilters(): void {
    this.filtrosSeleccionados.set([]);
    this.listProductFilterService.clearFiltrosSubject();
    this.listProductPriceService.clearPreciosSubject();
    this.listProductBrandService.clearBrandSubject();
    this.listProductBrandDiscountService.clearBrandSubjectDiscount();
    this.listProductSubcategoryService.clearSelectedSubcate();
  }

  public softReset(clearFilters: boolean = false): void {
    this.filtrosSeleccionados.set(
      clearFilters ?
        []
      : this.filtrosSeleccionados().filter(
          (f) => !['marca', 'subcategoria', 'precio', 'filtro'].includes(f.tipo),
        ),
    );

    if (clearFilters) this.listProductFilterService.clearFiltrosSubject();
  }

  async limpiarUrlParcial() {
    await this.route.navigate([], {
      queryParams: { precio: null, marca: null, filtros: null },
      queryParamsHandling: 'merge',
    });
  }
  async limpiarUrlTotal() {
    await this.route.navigate([], {
      queryParams: {
        precio: null,
        marca: null,
        filtros: null,
        cate: null,
        promo: null,
        alcance: null,
        criterio: null,
        [SearchConfig.grouperProp]: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
