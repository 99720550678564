import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListProductPriceService {
  private preciosSubject = new BehaviorSubject<any[]>([]);
  public preciosSubject$ = this.preciosSubject.asObservable();
  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.precio) {
        let data = res.precio.split(',');
        this.preciosSubject.next(data);
      }
    });
  }

  public addPrecio(rango: string) {
    let data = this.preciosSubject.getValue();

    data.push(rango);
    this.preciosSubject.next(data);
    this.armarUrl();
  }
  public async armarUrl(url?: string[]) {
    if (!url) {
      const urlTree = this.route.parseUrl(this.route.url);
      if (urlTree.root.children['primary']) {
        url = urlTree.root.children['primary'].segments.map((it) => it.path);
      } else {
        url = [];
      }
    }
    let precio;
    let data = this.preciosSubject.getValue();
    if (data && data.length == 0) {
      precio = { precio: null };
    } else {
      let idsString = data.join();
      precio = { precio: idsString };
    }

    await this.route.navigate(url, {
      queryParams: precio,
      queryParamsHandling: 'merge',
    });
  }
  agregarFiltroPrecio(rango) {
    for (const prop in rango) {
      this.limpiarFiltroPrecios(prop);
      if (rango[prop]) {
        this.addPrecio(`${prop}:${rango[prop]}`);
      }
    }
  }
  limpiarFiltroPrecios(arg: string) {
    let data = this.preciosSubject.getValue();
    console.log(data);

    let objIndex = data.findIndex((obj) => obj.includes(arg));
    if (objIndex > -1) {
      data.splice(objIndex, 1);
      this.preciosSubject.next(data);
    }
    this.armarUrl();
  }
  deleteFilter(id: string | number) {
    let data = this.preciosSubject.getValue();
    console.log('PARAMETROS', this.preciosSubject.getValue());
    let objIndex = data.findIndex((obj) => obj == id);
    if (objIndex != -1) {
      data.splice(objIndex, 1);
      this.preciosSubject.next(data);
    }
    this.armarUrl();
  }
  public clearPreciosSubject() {
    this.preciosSubject.next([]);
  }
}
