import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Brand } from '@core/models/piramide/Brand';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { PiramidalService } from '../piramidal.service';

@Injectable({
  providedIn: 'root',
})
export class ListProductBrandService {
  private brand: Brand;
  private brandSubject = new BehaviorSubject<Brand>(null);
  public brand$ = this.brandSubject.asObservable();
  private brandIndex: { [x: string]: Brand };
  public subcategoryId: string;
  promo: string;
  marca: string;
  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private piramidalSerivce: PiramidalService,
  ) {
    combineLatest([this.piramidalSerivce.brands.observable]).subscribe((res) => {
      this.brandIndex = this.piramidalSerivce.brands.cache;
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.marca) {
          this.subcategoryId = params.cate;
          this.chequearCambio(params.cate, params.marca);
        }
      });
    });
  }

  private chequearCambio(subca, marca) {
    if (subca == this.subcategoryId || this.subcategoryId == undefined) {
      this.changeByIdBrand(marca);
    } else {
      this.armarUrl(null);
    }
  }

  private changeByIdBrand(id?: number) {
    if (this.brand?.id != id) {
      this.brand = this.brandIndex[id];
      this.brandSubject.next(this.brand);
      this.route.navigate([], {
        queryParams: {
          marca: this.brand?.id ?? null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  public changeBrand(brand: Brand) {
    this.changeByIdBrand(brand?.id);
  }

  private armarUrl(brand, url?: string[]) {
    if (!url) {
      const urlTree = this.route.parseUrl(this.route.url);
      if (urlTree.root.children['primary']) {
        url = urlTree.root.children['primary'].segments.map((it) => it.path);
      } else {
        url = [];
      }
    }
    let queryParams = { marca: brand, promo: this.promo };
    if (this.brand) {
      (queryParams as any).criterio = null;
    }
    setTimeout(() => {
      this.route.navigate(url, {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }, 0);
  }
  clearBrandSubject() {
    this.brandSubject.next(null);
  }
}
