import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListProductBrandDiscountService {
  public brand: string;
  private brandSubject = new BehaviorSubject<string>(null);
  public brand$ = this.brandSubject.asObservable();

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log();
      if (params.brand_discount) {
        this.brandSubject.next(params.brand_discount);
      } else {
        this.clearBrandSubjectDiscount();
      }
    });
  }

  private armarUrl(brand, url?: string[]) {
    if (!url) {
      const urlTree = this.route.parseUrl(this.route.url);
      if (urlTree.root.children['primary']) {
        url = urlTree.root.children['primary'].segments.map((it) => it.path);
      } else {
        url = [];
      }
    }
    let queryParams = { brand_discount: brand };
    setTimeout(() => {
      this.route.navigate(url, {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }, 0);
  }
  change(brand: string) {
    console.log(brand);
    this.brandSubject.next(brand);
    this.armarUrl(brand);
  }
  clearBrandSubjectDiscount() {
    this.brandSubject.next(null);
  }
}
