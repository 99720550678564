import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltrosSubcategorias } from '@core/models/FiltrosSubcategorias';
import { BehaviorSubject } from 'rxjs';
import { Product } from '@core/models/piramide/Product';

@Injectable({
  providedIn: 'root',
})
export class ListProductFilterService {
  private filterSubject = new BehaviorSubject<any[]>([]);
  public filter$ = this.filterSubject.asObservable();
  private listaFiltrosSubject = new BehaviorSubject<FiltrosSubcategorias[]>([]);
  public listaFiltrosSubject$ = this.listaFiltrosSubject.asObservable();
  private filteredProductsSubject = new BehaviorSubject<Product[]>([]);
  public filteredProducts$ = this.filteredProductsSubject.asObservable();
  private listaMarcasSubject = new BehaviorSubject<any[]>([]);
  public listaMarcasSubject$ = this.listaMarcasSubject.asObservable();
  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.filtros) {
        let data = res.filtros.split(',');
        this.filterSubject.next(data);
      }
    });
  }

  //Agrego un filtro al observable
  public addFilter(id: string): void {
    let data = this.filterSubject.getValue();

    data.push(id);
    this.filterSubject.next(data);

    this.armarUrl();
  }

  public setListaMarcas(lista: any): void {
    this.listaMarcasSubject.next(lista);
  }

  public listaFiltros(lista: any): void {
    this.listaFiltrosSubject.next(lista);
  }

  public addListaFiltros(lista: any): void {
    let data: FiltrosSubcategorias[] = [];
    data = this.listaFiltrosSubject.getValue();
    data.push(lista);
    this.listaFiltrosSubject.next(lista);
  }

  public deleteListaFiltros(): void {
    this.listaFiltrosSubject.next(null);
  }

  public deleteAllFiltros(): void {
    this.filterSubject.next([]);
    this.deleteListaFiltros();
    this.armarUrl();
  }

  public armarUrl(url?: string[]): void {
    if (!url) {
      const urlTree = this.route.parseUrl(this.route.url);
      if (urlTree.root.children['primary']) {
        url = urlTree.root.children['primary'].segments.map((it) => it.path);
      } else {
        url = [];
      }
    }
    let filtro;
    let data = this.filterSubject.getValue();
    if (data && data.length == 0) {
      filtro = { filtros: null };
    } else {
      let idsString = data.join();
      filtro = { filtros: idsString };
    }

    setTimeout(() => {
      this.route.navigate(url, {
        queryParams: filtro,
        queryParamsHandling: 'merge',
      });
    }, 0);
  }

  //Borro filtro de la lista de chips
  deleteFilter(id: string | number): void {
    let filtros = this.filterSubject.getValue();

    let objIndex = filtros.findIndex((obj) => obj == id);
    if (objIndex != -1) {
      filtros.splice(objIndex, 1);
      this.filterSubject.next(filtros);
    }
    this.armarUrl();
  }

  public setProductosFiltrados(listaProductos): void {
    this.filteredProductsSubject.next(listaProductos);
  }

  public clearFiltrosSubject(): void {
    this.filterSubject.next([]);
  }
}
