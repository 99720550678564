import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListProductCriterionService {
  private criterio: string = '';
  private criterioSubject = new BehaviorSubject<string>(null);
  public criterio$ = this.criterioSubject.asObservable();

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.criterio) {
        this.ejectuteChange(params.criterio);
      }
    });
  }
  public ejectuteChange(criterio: string) {
    if (criterio != this.criterio) {
      this.criterio = criterio;
      this.criterioSubject.next(criterio);
    }
  }

  changeCriterio(view: string) {
    this.ejectuteChange(view);
    this.armarUrl();
  }

  public armarUrl(url?: string[]) {
    if (!url) {
      const urlTree = this.route.parseUrl(this.route.url);
      if (urlTree.root.children['primary']) {
        url = urlTree.root.children['primary'].segments.map((it) => it.path);
      } else {
        url = [];
      }
    }
    let queryParams = { criterio: this.criterio };
    setTimeout(() => {
      this.route.navigate(url, {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }, 0);
  }
}
