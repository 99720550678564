import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subcategory } from '@core/models/piramide/Subcategory';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, Observable, of, shareReplay, Subject, takeUntil } from 'rxjs';
import { FiltrosSubcategorias } from '@core/models/FiltrosSubcategorias';
import { PiramidalService } from '../piramidal.service';

@Injectable({
  providedIn: 'root',
})
export class ListProductSubcategoryService implements OnDestroy {
  private readonly selectedSubcategoryBS = new BehaviorSubject<Subcategory | null>(null);
  private readonly _destroyS = new Subject<boolean>();

  public readonly selectedSubcategory$ = this.selectedSubcategoryBS.pipe(shareReplay());
  public readonly characteristicFilters$: Observable<FiltrosSubcategorias[]> = this._http
    .get<FiltrosSubcategorias[]>(`${environment.apiStatic}productos_caracteristicas_filtro`)
    .pipe(shareReplay());
  public subcategoriesIndex: Record<number, Subcategory> = {};
  public readonly subcategories$: Observable<Subcategory[]> =
    this._piramidalService.subcategories.observable.pipe(shareReplay());

  public get selectedSubcategory(): Subcategory | null {
    return this.selectedSubcategoryBS.getValue();
  }

  constructor(
    private _http: HttpClient,
    private _piramidalService: PiramidalService,
  ) {
    this.loadSubcates();
  }

  private loadSubcates(): void {
    this.subcategories$
      .pipe(
        catchError((e) => of([] as Subcategory[])),
        takeUntil(this._destroyS),
      )
      .subscribe((subcates) => {
        this.subcategoriesIndex = {};
        subcates?.forEach((s) => {
          this.subcategoriesIndex[s.id] = s;
        });
      });
  }

  public changeSubcate(subcate: Subcategory): void {
    if (this.selectedSubcategoryBS.value?.id == subcate.id) return;

    this.selectedSubcategoryBS.next(subcate);
  }

  public clearSelectedSubcate(): void {
    this.selectedSubcategoryBS.next(null);
  }

  public ngOnDestroy(): void {
    this._destroyS.next(true);
    this._destroyS.complete();
  }
}
